import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput, DateInput, SelectInput

} from "react-admin";
import React from "react";
import {CustomEditToolbar} from '../ToolBars/ToolBar'


export const HistoryEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomEditToolbar/>}>
            <TextInput disabled source="id" />
            <TextInput disabled source="email" />
            <TextInput disabled source="did" />
            <TextInput  disabled source="direction" />
            <DateInput disabled source="date" />
            <SelectInput source="status" choices={[
                { id: 'paid', name: 'paid' },
                { id: 'wait', name: 'wait' },
                { id: 'cancel', name: 'cancel' },
            ]} />
            <TextInput source="transactionHash" />
            <NumberInput disabled source="amount" />


        </SimpleForm>
    </Edit>
);